<script setup lang="ts">
const error = useError();
const errorDetailsFireBang = computed(() => {
  if (error.value && "statusCode" in error.value) {
    const statusCode = error.value.statusCode;
    if (statusCode === 400) {
      return {
        element: `<strong>お探しのページは見つかりません</strong>お探しのページは見つかりません。<br /> ご不明な点がございましたら、<a href="mailto:info@bang.co.jp">info@bang.co.jp</a>までご連絡ください。`,
        title: "400 Bad Request",
      };
    } else if (statusCode === 404) {
      return {
        element: `<strong>お探しのページは見つかりません</strong>お探しのページは見つかりません。<br /> ご不明な点がございましたら、<a href="mailto:info@bang.co.jp">info@bang.co.jp</a>までご連絡ください。`,
        title: "404 Not Found",
      };
    } else if (statusCode === 408) {
      return {
        element: `<strong>タイムアウトしました</strong>アクセス集中、またはメンテナンスのため、サーバーが応答しません。<br /> ご不明な点がございましたら、<a href="mailto:info@bang.co.jp">info@bang.co.jp</a>までご連絡ください。`,
        title: "408 Request Time-out",
      };
    } else if (statusCode === 503) {
      return {
        element: `<strong>サービス一時停止</strong>アクセス集中、またはメンテナンスのため、サーバーが応答しません。<br /> ご不明な点がございましたら、<a href="mailto:info@bang.co.jp">info@bang.co.jp</a>までご連絡ください。`,
        title: "503 Service Unavailable",
      };
    } else {
      return {
        element: `<strong>サーバーエラー</strong>システムエラーが発生しました。<br />  恐れ入りますが、TOPページから再度アクセスをお願いします。<br /> ご不明な点がございましたら、<a href="mailto:info@bang.co.jp">info@bang.co.jp</a>までご連絡ください。`,
        title: "500 Internal Server Error",
      };
    }
  }
});

const errorDetailsHoken = computed(() => {
  if (error.value && "statusCode" in error.value) {
    const statusCode = error.value.statusCode;
    if (statusCode === 400) {
      return {
        element: `<strong>お探しのページは見つかりませんでした。</strong><p>URLが変更・削除されたか、現在ご利用できない可能性がございます。</p>`,
        title: "400 Bad Request",
      };
    } else if (statusCode === 404) {
      return {
        element: `<strong>お探しのページは見つかりませんでした。</strong><p>URLが変更・削除されたか、現在ご利用できない可能性がございます。</p>`,
        title: "404 Not Found",
      };
    } else if (statusCode === 408) {
      return {
        element: `<strong>タイムアウトしました。</strong><p>アクセス集中、またはメンテナンスのため、サーバーが応答しません。</p>`,
        title: "408 Request Time-out",
      };
    } else if (statusCode === 503) {
      return {
        element: `<strong>サービス一時停止</strong><p>アクセス集中、またはメンテナンスのため、サーバーが応答しません。</p>`,
        title: "503 Service Unavailable",
      };
    } else {
      return {
        element: `<strong>サーバーエラー</strong><p>システムエラーが発生しました。</p>`,
        title: "500 Internal Server Error",
      };
    }
  }
});

const currentDomain = ref('');

const checkDomainName = ref(true)

onMounted(() => {
  currentDomain.value = window.location.href
  checkDomainName.value = window.location.href.includes('bang.co.jp')
});

useHead({
  titleTemplate: "",
  title: errorDetailsFireBang.value?.title,
  meta: [
    {
      hid: "description",
      name: "description",
      content: "",
    },
    {
      hid: "og:site_name",
      property: "og:site_name",
      content: "",
    },
    {
      hid: "og:url",
      property: 'og:url',
      content: "",
    },
    {
      hid: "og:title",
      property: 'og:title',
      content: "",
    },
    {
      hid: "og:description",
      property: 'og:description',
      content: "",
    },
    {
      hid: "og:image",
      property: "og:image",
      content: "",
    }
  ],
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: computed(() => checkDomainName.value
      ? '/favicon.ico'
      : '/favicon_hoken.ico'),
    },
  ]
});
</script>

<template>
  <div class="container">
    <div v-if="checkDomainName" class="fireBang">
      <header>
        <h1>
          <img
            src="~/assets/images/logo.png"
            alt="保険スクエアbang! 火災保険"
          />
        </h1>
      </header>
      <main role="main">
        <div class="error" v-html="errorDetailsFireBang?.element"></div>
        <a href="/" class="goTop">TOPページへ戻る</a>
      </main>
      <footer>
        <small>Copyright &copy; 2000 WebCrew Inc. All rights reserved.</small>
      </footer>
    </div>
    <div v-else class="hoken">
      <header>
        <h1>
          <picture>
            <source
              srcset="
                ~/assets/images/hoken/logo_pc.png    1x,
                ~/assets/images/hoken/logo_pc@2x.png 2x
              "
              width="173"
              height="38"
              media="(min-width: 751px)"
            />
            <img
              class="logo"
              src="~/assets/images/hoken/logo.png"
              width="320"
              height="70"
              alt="火災保険の比較屋さん"
            />
          </picture>
        </h1>
        <div class="header-copy">
          保険のプロがあなたに<br class="sp" />ぴったりの火災保険をご提案
        </div>
      </header>
      <main role="main">
        <img
          class="icon"
          src="~/assets/images/hoken/icon_error.png"
          width="96"
          height="88"
        />
        <div class="error" v-html="errorDetailsHoken?.element"></div>
        <a class="goTop" href="/">TOPページへ戻る</a>
      </main>
      <footer>
        <small class="copyright"
          >Copyright &copy; 2023 Bang Inc. All Rights Reserved.</small
        >
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/*---------------------------------------
  font
  ----------------------------------------*/
.hoken {
  font-family: Hiragino Kaku Gothic ProN, Osaka, 'メイリオ', 'ＭＳ Ｐゴシック',
    Arial, Helvetica, sans-serif;
}
/*---------------------------------------
  header
  ----------------------------------------*/
.fireBang header {
  padding: 2.5% 4% 2%;
  h1 {
    img {
      width: 28.84%;
      height: auto;
      display: block;
    }
  }
}
.hoken header {
  padding: 1.3% 3.2% 2.9%;
  display: flex;
  align-items: flex-start;
  h1 {
    width: 45.585%;
  }
  .header-copy {
    margin: auto 0 0 auto;
    text-align: right;
    font-size: px(10);
    line-height: 1.4;
    width: 45%;
  }
}
/*---------------------------------------
  main
  ----------------------------------------*/
.fireBang main {
  padding: 4%;
}

.fireBang a {
  &.goTop {
    margin: 0 auto;
    padding: 5% 0;
    width: 8em;
    display: block;
  }
}

.hoken main {
  border-top: 1px solid #dfdfdf;
  padding: 10.7% 4.3%;
}
/*---------------------------------------
  .error
  ----------------------------------------*/
.fireBang .error {
  :deep {
    padding: 5%;
    border: 2px solid #f30;
    box-sizing: border-box;
    strong {
      margin-bottom: 10px;
      padding: 3px 0 3px 35px;
      display: block;
      color: #f30;
      background: url(~/assets/images/error/icon_error.gif) left 0.3em
        no-repeat;
      font-size: px(19.4);
      font-weight: bold;
    }
  }
}

.hoken {
  .icon {
    width: 12.8%;
    display: block;
    margin: 0 auto 5.9%;
  }
  a {
    &.goTop {
      margin: 0 auto;
      width: 90.7%;
      display: block;
      border: 1px solid #333333;
      border-radius: 4px;
      padding: 4.3% 0;
      font-weight: 700;
      color: $colorBasicText;
      text-decoration: none;
      text-align: center;
      font-size: px(16);
    }
  }
  :deep(.error) {
    strong {
      margin-bottom: 6.4%;
      display: block;
      color: #ff5900;
      font-size: px(18);
      font-weight: 700;
      text-align: center;
    }
    p {
      margin-bottom: 9.6%;
    }
  }
}

/*body.pageUsercompleted .error {*/
/*  border: 2px solid #ccc;*/
/*}*/
/*---------------------------------------
  footer
  ----------------------------------------*/
.fireBang footer {
  padding: 2.5% 4% 3.5%;
  color: #fff;
  font-size: px(12.5);
  background-color: #1c79c6;
  text-align: center;
  line-height: 1.2;
}

.hoken .copyright {
  border-top: 1px solid #dcdcdc;
  padding: 2.8% 0;
  display: block;
  font-size: px(12);
  text-align: center;
}

@media screen and (min-width: 668px) {
  /*---------------------------------------
    main
    ----------------------------------------*/
  .fireBang a.goTop {
    padding: 10px 0;
    font-size: px(13.4);
  }

  /*---------------------------------------
    footer
    ----------------------------------------*/
  .fireBang footer {
    padding: 10px 0 8px;
  }
}

@media screen and (min-width: 960px) {
  /*---------------------------------------
    header
    ----------------------------------------*/
  .fireBang header {
    margin: 0 auto;
    padding: 10px 0;
    width: 620px;
    h1 {
      img {
        width: 170px;
        height: auto;
        display: block;
      }
    }
  }
  .hoken header {
    padding: 9px 17px 17px;
    max-width: 960px;
    min-width: 700px;
    margin: 0 auto;
    align-items: center;
    h1 {
      width: 173px;
    }
    .header-copy {
      margin: 0 0 0 auto;
      padding-top: 5px;
      font-size: px(14);
      .sp {
        display: none;
      }
    }
  }
  /*---------------------------------------
    main
    ----------------------------------------*/
  .fireBang main {
    padding: 0;
  }
  .hoken main {
    padding: 56px 0;
  }
  /*---------------------------------------
    .error
    ----------------------------------------*/
  .fireBang .error {
    :deep {
      margin: 0 auto;
      padding: 20px 40px;
      width: 620px;
      font-size: px(13.4);
      box-sizing: border-box;

      strong {
        font-size: px(20);
        background: url(~/assets/images/error/icon_error.gif) left 0.2em
          no-repeat;
      }
    }
  }
  .hoken {
    .icon {
      width: 48px;
      margin: 0 auto 16px;
    }
    a {
      &.goTop {
        width: 311px;
        padding: 16px 0;
      }
    }
    :deep(.error) {
      strong {
        margin-bottom: 5px;
        font-size: px(28);
      }
      p {
        margin-bottom: 53px;
        text-align: center;
      }
    }
  }
  /*---------------------------------------
  footer
  ----------------------------------------*/
  .hoken .copyright {
    padding: 16px 0;
    font-size: px(10);
    height: 44px;
    box-sizing: border-box;
  }
}
</style>